import React from "react"

import Layout from '../components/layout'

export default function Success() {
 // let title="Thank you!"
 // let description="Purchase Successful!"

  return (
    <Layout>
      <div className="text-center">
        <h2>Purchase Successful!</h2>
        <p>
          Thank you for doing business with us! You will be contacted via email within 48 hours.
        </p>
        <br /><br />
        <p className="pb-3">
          <h3>Some things that speed up the process: </h3><br />
          <ul className="text-daccent">
            <li>
              Have logo and/or branding colors ready or just some colors you like.
            </li>
            <li>
              Prepare specific images, information, and items you want on the website.
            </li>
            <li>
              Draw a sketch of a what you want it to look like.
            </li>
            <li>
              Have names of pages or categories you want information stored under. 
            </li>
            <li>
              Describe any specific animations or effects you want.
            </li>
          </ul>
        </p>
        <h2 className="text-brand">Refunds are at our discretion!</h2>
      </div>
    </Layout>
  )
}
